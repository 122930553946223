import { createSelector } from 'reselect'
import { escapeRegExp } from 'lodash'

import { MT4Connector } from '@bdswiss/mt4-connector'

import { assetGroupsSelector, activeAssetGroupSelector } from './assetGroups'
import { activeAssetSelector, assetFilterSelector, tradeModeSelector } from './app'
import { RootState, Prices } from '../types'
import { openPositionsSelector } from './positions'
import { TradeMode } from '../../enums'
import { getTradeableAssets } from '../../utils'
import { guidedTourRunSelector } from './guidedTour'
import { currentAccountSelector } from './accounts'
import { signalRiskDisclaimerSelector } from './ui'

const mt4 = MT4Connector.Instance

export const pricesSelector = (): Prices => mt4.assets

export const currentSymbolPriceSelector = createSelector(
  (state: RootState) => state.app.activeAsset,
  pricesSelector,
  (activeAsset, prices) => prices[activeAsset],
)

export const currentPriceValueSelector = createSelector(
  currentSymbolPriceSelector,
  tradeModeSelector,
  (currentPrice, tradeMode) =>
    tradeMode === TradeMode.buy ? currentPrice.mask : tradeMode === TradeMode.sell ? currentPrice.mbid : 0,
)

export const currentSymbolLabelSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.label,
)

export const currentSymbolDigitsSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.digits,
)

export const currentSymbolLotMinSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.lotMin * currentPrice?.lotSize,
)

export const currentSymbolLotMaxSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.lotMax * currentPrice?.lotSize,
)

export const currentSymbolMinSizeIncrementSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.lotStep * currentPrice?.lotSize,
)

export const currentSymbolLotSizeSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.lotSize,
)

export const currentSymbolLotStepSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.lotStep,
)

export const currentSymbolPrecisionSelector = createSelector(
  currentSymbolLotSizeSelector,
  currentSymbolLotStepSelector,
  (lotSize, lotStep) => (lotSize * lotStep).toString().split('.')[1]?.length || 0,
)

export const currentSymbolTpSlPipValueSelector = createSelector(currentSymbolPriceSelector, (currentPrice) => {
  return currentPrice?.digits ? Math.pow(10, -currentPrice?.digits) : undefined
})

export const currentMarginCurrencySelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.marginCurrency,
)

export const currentMarginModePriceSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.marginMode,
)

export const currentPercentagePriceSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice?.percentage,
)

export const currentSecurityPriceSelector = createSelector(
  currentSymbolPriceSelector,
  (currentPrice) => currentPrice.security,
)

export const hasCurrentSymbolPriceSelector = createSelector(currentSymbolPriceSelector, (price) => !!price)

export const currentSymbolDescriptionSelector = createSelector(
  (state: RootState) => state.app.activeAsset,
  pricesSelector,
  (activeAsset, prices) => activeAsset && prices[activeAsset]?.description,
)

export const currentSymbolSessionsSelector = createSelector(
  (state: RootState) => state.app.activeAsset,
  pricesSelector,
  (activeAsset, prices) => activeAsset && prices[activeAsset]?.sessions,
)

export const getAssetLabelSelector = createSelector(
  pricesSelector,
  (state: RootState, symbol: string) => symbol,
  (prices, activeAsset) => activeAsset && prices[activeAsset]?.label,
)

export const getSymbolMarketStateSelector = createSelector(
  pricesSelector,
  (state: RootState, symbol: string) => symbol,
  (prices, activeAsset) => (activeAsset && prices[activeAsset] ? prices[activeAsset].marketState : undefined),
)

export const tradeableAssetsSelector = createSelector(
  pricesSelector,
  openPositionsSelector,
  assetGroupsSelector,
  (prices, openPositions, assetGroups) => {
    const collator = new Intl.Collator()
    const symbols = Object.values(assetGroups)
      .filter((group) => group.label !== 'favourites')
      .flatMap(({ symbols }) => symbols)

    return getTradeableAssets(prices, openPositions, symbols).sort((a, b) =>
      collator.compare(a.normalizedSymbol, b.normalizedSymbol),
    )
  },
)

export const filteredAssetsSelector = createSelector(
  assetGroupsSelector,
  activeAssetGroupSelector,
  tradeableAssetsSelector,
  assetFilterSelector,
  guidedTourRunSelector,
  (groups, activeGroup, prices, filter, isGuidedTour) => {
    if (filter) {
      return prices.filter((price) =>
        new RegExp(escapeRegExp(filter.replace('/', '')), 'i').test([price.symbol, price.description].join('|')),
      )
    } else if (!groups[activeGroup] || activeGroup === 'all') {
      return prices
    }
    const activeGroupSymbols = groups[activeGroup]['symbols']
    const filteredAssets = prices.filter((price) => activeGroupSymbols.includes(price.symbol))
    return !isGuidedTour
      ? filteredAssets
      : filteredAssets.sort((a, b) => activeGroupSymbols.indexOf(a.symbol) - activeGroupSymbols.indexOf(b.symbol))
  },
)

export const indexOfActiveAssetSelector = createSelector(
  filteredAssetsSelector,
  activeAssetSelector,
  (prices, activeAsset) => prices.map((p) => p.symbol).indexOf(activeAsset),
)

export const filteredAssetsWithNormalizedSymbolSelector = createSelector(
  assetGroupsSelector,
  activeAssetGroupSelector,
  tradeableAssetsSelector,
  assetFilterSelector,
  guidedTourRunSelector,
  (groups, activeGroup, prices, filter, isGuidedTour) => {
    if (filter) {
      return prices.filter((price) =>
        new RegExp(escapeRegExp(filter.replace('/', '')), 'i').test([price.symbol, price.description].join('|')),
      )
    } else if (!groups[activeGroup] || activeGroup === 'all') {
      return prices
    }
    const activeGroupSymbols = groups[activeGroup]['symbols']
    const filteredAssets = prices.filter((price) => activeGroupSymbols.includes(price.normalizedSymbol))
    return !isGuidedTour
      ? filteredAssets
      : filteredAssets.sort((a, b) => activeGroupSymbols.indexOf(a.symbol) - activeGroupSymbols.indexOf(b.symbol))
  },
)

export const displayRiskDisclaimerSelector = createSelector(
  filteredAssetsWithNormalizedSymbolSelector,
  activeAssetGroupSelector,
  currentAccountSelector,
  signalRiskDisclaimerSelector,
  (assets, activeAssetGroup, currentAccount, riskDisclaimer) =>
    riskDisclaimer &&
    assets.length &&
    currentAccount &&
    !currentAccount.__typename.includes('MT5') &&
    activeAssetGroup === 'trendsAnalysis',
)
