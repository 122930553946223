import { isActionOf } from 'typesafe-actions'
import { combineLatest, EMPTY, from, merge, of, zip } from 'rxjs'
import {
  catchError,
  delay,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  pairwise,
  switchMap,
  take,
} from 'rxjs/operators'
import i18next from 'i18next'
import camelCase from 'lodash/camelCase'

import { AssetGroup, AssetTradeMode, Direction } from '@bdswiss/mt4-connector'

import { SymbolsStorage } from '../../components/Chart/datafeed/symbols-storage'
import { DocumentWithFullscreenAPI, RootEpic } from '../types'
import {
  appChangeActiveAsset,
  appChangeTradeMode,
  changeActiveAssetGroup,
  changeLocation,
  clearActiveSignal,
  currentMobilePositionTab,
  error,
  getUserRequest,
  getUserSuccess,
  initialize,
  initialized,
  mt4AssetGroups,
  mt4Login,
  pricesInitAction,
  pricesUpdatedAction,
  selectedOpenedPosition,
  selectedPendingPosition,
  setActiveSignal,
  setDefaultAsset,
  setOverrideParamsAction,
  signalsRiskDisclaimer,
  toggleFullscreen,
  updateAccountLogin,
  updateFormValues,
} from '../actions'
import {
  clickTrendAnalysisAsset,
  demoTradeIntent,
  expandOpenOrders,
  realTradeIntent,
  SOURCES_BY_ASSET_GROUP,
  switchAccount,
  viewPendingTrades,
  viewTrendAnalysis,
} from '../../analytics/events'
import { trackEvent } from '../../analytics/firebaseLogger'
import { Location, OrderTypes, PostMessageActionType, TradeMode } from '../../enums'
import { currentPriceValueSelector, currentSymbolLabelSelector, getActiveSignal } from '../selectors'
import { getCookie, getTradeableAssets, getUrlParam, history, sendPostMessage } from '../../utils'
import { ICompanyConfig } from '../../config'
import { toast } from 'react-toastify'

export const loadConfigurationEpic: RootEpic = (action$, state$, { config, bugsnag, loadConfiguration }) => {
  return action$.pipe(
    filter(isActionOf([initialize])),
    exhaustMap(() =>
      from(
        loadConfiguration<{ data: ICompanyConfig }>(
          getUrlParam('companyKey') || process.env.REACT_APP_CONFIG_COMPANY_KEY,
        ),
      ).pipe(
        map(({ data }) => {
          config.mergeWith(data)
          return getUserRequest()
        }),
        catchError((err) => {
          toast.error(err.message, { autoClose: false, closeButton: false, closeOnClick: false, draggable: false })
          bugsnag.notify(err)
          return of(error(err.message))
        }),
      ),
    ),
  )
}

export const loadTranslationEpic: RootEpic = (action$, state$, { loadTranslations, bugsnag }) => {
  return action$.pipe(
    filter(isActionOf([getUserSuccess])),
    exhaustMap(({ payload: { locale } }) =>
      from(loadTranslations(locale || 'en')).pipe(
        map(() => {
          if (locale) {
            i18next.changeLanguage(locale).then()
          }
          return initialized()
        }),
        catchError((err) => {
          bugsnag.notify(err)
          return of(error(err))
        }),
      ),
    ),
  )
}

export const setOverrideParamsEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([initialize])),
    switchMap(() => of(setOverrideParamsAction(Object.fromEntries(new URLSearchParams(window.location.search))))),
  )

export const setActiveTabEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([setOverrideParamsAction])),
    switchMap(({ payload: { positionType } }) => {
      if (positionType) {
        const path = location.pathname.split('/').slice(0, 3).join('/')
        switch (positionType) {
          case 'open':
            history.push(`${path}/${Location.positions}`)
            break
          case 'pending':
            history.push(`${path}/${Location.order}`)
            break
          case 'closed':
            history.push(`${path}/${Location.history}`)
            break
        }

        return of(changeLocation(location.pathname.split('/').slice(3).join('/'), {}))
      }

      return EMPTY
    }),
  )

export const setActivePositionEpic: RootEpic = (action$) =>
  combineLatest([
    action$.pipe(filter(isActionOf(setOverrideParamsAction))),
    action$.pipe(filter(isActionOf(mt4Login))),
  ]).pipe(
    delay(0),
    switchMap(
      ([
        {
          payload: { positionNumber },
        },
        {
          payload: { trades: marketOrders, pendingOrders },
        },
      ]) => {
        const path = location.pathname.split('/').slice(0, 3).join('/'),
          marketOrder = marketOrders.find((order) => order.id?.toString() === positionNumber),
          pendingOrder = pendingOrders.find((order) => order.id?.toString() === positionNumber)

        if (positionNumber && marketOrder) {
          history.push(`${path}/${Location.positions}`)
          return of(
            selectedOpenedPosition(positionNumber, {
              activeAsset: marketOrder.symbol,
              currentLocation: Location.positions,
            }),
          )
        } else if (positionNumber && pendingOrder) {
          history.push(`${path}/${Location.order}`)
          return of(
            selectedPendingPosition(positionNumber, {
              activeAsset: pendingOrder.symbol,
              currentLocation: Location.order,
            }),
          )
        }

        return EMPTY
      },
    ),
  )

export const setOrderTypeEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isActionOf([setOverrideParamsAction])),
    switchMap(({ payload: { orderType } }) => {
      switch (orderType) {
        case 'market':
          return of(updateFormValues({ formOrderType: OrderTypes.marketOrder }))
        case 'pending':
          return of(updateFormValues({ formOrderType: OrderTypes.pendingOrder }))
        default:
          return EMPTY
      }
    }),
  )
}

export const changeAssetGroupEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isActionOf([changeActiveAssetGroup])),
    switchMap(({ payload }) => {
      if (payload === 'trendsAnalysis') {
        trackEvent(viewTrendAnalysis)
        return EMPTY
      }
      return of(clearActiveSignal())
    }),
  )
}

export const changeLocationEpic: RootEpic = (action$, state$, { mt4Connector }) => {
  return action$.pipe(
    filter(isActionOf([changeLocation])),
    switchMap(({ payload }) => {
      const open = state$.value.app.selectedOpenedPosition
      const pending = state$.value.app.selectedPendingPosition
      if (payload === '/positions') {
        trackEvent(expandOpenOrders)
        const order = open && mt4Connector.positions.get(+open)
        return order ? of(appChangeActiveAsset(order.symbol)) : EMPTY
      } else if (payload === '/order') {
        const activeAccount = state$.value.accounts.data.find((account) => account.login === state$.value.app.login)
        activeAccount && trackEvent(viewPendingTrades, { accountType: activeAccount.isDemo ? 'demo' : 'real' })
        const order = pending && mt4Connector.pendingOrders.get(+pending)
        return order ? of(appChangeActiveAsset(order.symbol)) : EMPTY
      }
      return EMPTY
    }),
  )
}

export const changeMobilePositionTypeEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf([currentMobilePositionTab])),
    switchMap(({ payload }) => {
      if (payload === 'pending') {
        const activeAccount = state$.value.accounts.data.find((account) => account.login === state$.value.app.login)
        activeAccount && trackEvent(viewPendingTrades, { accountType: activeAccount.isDemo ? 'demo' : 'real' })
      } else {
        trackEvent(expandOpenOrders)
      }
      return EMPTY
    }),
  )
}

export const tradingFormPanelEpic: RootEpic = (action$, state$) => {
  return state$.pipe(
    filter((state) => state.app.activeTab === Location.trade),
    map((state) => ({
      tradingFormPanel: state.ui.tradingFormPanel,
      asset: state.app.activeAsset,
      login: state.app.login,
    })),
    pairwise(),
    filter(([{ tradingFormPanel: oldTradingFormPanel, asset: oldAsset }, { tradingFormPanel, asset, login }]) => {
      return (
        !!login &&
        ((!oldTradingFormPanel && tradingFormPanel) || (!oldAsset.length && !!asset.length && tradingFormPanel))
      )
    }),
    map(([, { asset, login }]) => {
      const activeAccount = state$.value.accounts.data.find((account) => account.login === login)

      return {
        event: activeAccount?.isDemo ? demoTradeIntent : realTradeIntent,
        source: SOURCES_BY_ASSET_GROUP[state$.value.assetGroups.activeGroup],
        login,
        symbol: asset,
      }
    }),
    switchMap(({ event, source, login, symbol }) => {
      trackEvent(event, { source, login, symbol })
      return EMPTY
    }),
  )
}

export const changeActiveAssetEpic: RootEpic = (action$, state$, { mt4Connector }) =>
  action$.pipe(filter(isActionOf(appChangeActiveAsset))).pipe(
    pairwise(),
    switchMap(([{ payload: oldAsset }, { payload: newAsset }]) => {
      mt4Connector.unsubscribeFromSymbol(oldAsset)
      mt4Connector.subscribeToSymbol(newAsset)
      return EMPTY
    }),
  )

export const defaultActiveAssetEpic: RootEpic = (action$, state$, { mt4Connector }) =>
  zip(action$.pipe(filter(isActionOf(pricesInitAction))), action$.pipe(filter(isActionOf(mt4AssetGroups)))).pipe(
    switchMap(() => {
      let activeAsset = state$.value.app.overrideParams?.asset || localStorage.getItem('activeAsset')
      const symbols = Object.values(mt4Connector.assetGroups).flatMap(({ symbols }) => symbols)
      const prices = getTradeableAssets(mt4Connector.assets, Array.from(mt4Connector.positions.values()), symbols)
      const alternativeAssetRegex = /EURUSD(\.+)?/
      const alternativeAsset = prices.find((price) => alternativeAssetRegex.test(price.symbol))

      if (!activeAsset) {
        activeAsset =
          alternativeAsset?.symbol || mt4Connector.assetGroups[0]?.symbols[0] || Object.keys(mt4Connector.assets)[0]
      }

      if (activeAsset) {
        const simpleAsset = /\.{2}/gi.test(activeAsset) ? activeAsset.slice(0, activeAsset.indexOf('..')) : activeAsset
        const activeAssetRegex = new RegExp(simpleAsset + '(.+)?')
        const assetOfPrices = prices
          .sort((a, b) => a.symbol?.localeCompare(b.symbol))
          .find((price) => activeAssetRegex.test(price.symbol))

        activeAsset = assetOfPrices
          ? assetOfPrices.symbol
          : mt4Connector.assetGroups[0]?.symbols[0] || Object.keys(mt4Connector.assets)[0]
      }
      const currentAsset = prices.find((price) => price.symbol === activeAsset)
      SymbolsStorage.Instance._init(mt4Connector.assets)

      return merge(
        of(
          appChangeActiveAsset(activeAsset, {
            tradeMode:
              currentAsset && [AssetTradeMode.enable, AssetTradeMode.buyOnly].includes(currentAsset.trade)
                ? TradeMode.buy
                : TradeMode.sell,
          }),
        ),
        of(setDefaultAsset(activeAsset)),
      )
    }),
  )

export const changeAssetGroupBySymbolEpic: RootEpic = (action$, state$) =>
  combineLatest([
    action$.pipe(filter(isActionOf(setDefaultAsset))),
    action$.pipe(filter(isActionOf(mt4AssetGroups))),
  ]).pipe(
    take(1),
    switchMap(([{ payload }, { payload: assetGroups }]) => {
      const [symbolGroup]: AssetGroup[] = Object.values(state$.value.assetGroups.data).filter((group) =>
        group.symbols.some((symbol) => symbol === payload),
      )

      return of(
        changeActiveAssetGroup(
          state$.value.assetGroups.data[camelCase(state$.value.app.overrideParams?.assetGroup)]?.label ||
            symbolGroup?.label ||
            assetGroups[0]?.label,
        ),
        signalsRiskDisclaimer(!getCookie('topMenuAlert')),
      )
    }),
  )

export const accountLoginEpic: RootEpic = (action$, state$) => {
  return action$.pipe(filter(isActionOf(updateAccountLogin))).pipe(
    distinctUntilChanged((previous, current) => previous.payload === current.payload),
    pairwise(),
    switchMap(([{ payload: oldLogin }, { payload: login }]) => {
      const previousAccount = state$.value.accounts.data.find((account) => account.login === oldLogin)
      const activeAccount = state$.value.accounts.data.find((account) => account.login === login)
      const accountTypeChanged = previousAccount && activeAccount && previousAccount.isDemo !== activeAccount.isDemo
      const accountType = activeAccount?.isDemo ? 'demo' : 'real'

      trackEvent(switchAccount, { type: accountTypeChanged ? accountType : undefined })
      return EMPTY
    }),
  )
}

export const setActiveSignalEpic: RootEpic = (action$, state$) => {
  return action$.pipe(filter(isActionOf(setActiveSignal))).pipe(
    switchMap(() => {
      const activeSignal = getActiveSignal(state$.value)
      activeSignal &&
        trackEvent(clickTrendAnalysisAsset, {
          asset: activeSignal.symbol,
          direction: activeSignal.direction === Direction.UP ? 'up' : 'down',
          type: activeSignal.keylevel ? 'Key Level' : 'Chart Pattern',
          name: activeSignal.pattern ? activeSignal.pattern.name : undefined,
          resolution: activeSignal.resolution,
          status: activeSignal.complete ? 'complete' : 'emerging',
        })
      return EMPTY
    }),
  )
}

export const toggleFullscreenEpic: RootEpic = (action$, state$) => {
  return action$.pipe(filter(isActionOf(toggleFullscreen))).pipe(
    switchMap(() => {
      if (state$.value.ui.isFullscreen && !document.fullscreenElement && state$.value.ui.fullscreenElement) {
        const fullscreenNode = state$.value.ui.fullscreenElement
        console.log('is there a fullscreen element?', fullscreenNode)
        if ('requestFullscreen' in fullscreenNode) {
          fullscreenNode.requestFullscreen()
        } else if ('webkitRequestFullscreen' in fullscreenNode) {
          fullscreenNode.webkitRequestFullscreen?.()
        } else if ('msRequestFullscreen' in fullscreenNode) {
          fullscreenNode.msRequestFullscreen?.()
        } else if ('mozRequestFullScreen' in fullscreenNode) {
          fullscreenNode.mozRequestFullScreen?.()
        }
      } else if (!state$.value.ui.isFullscreen && document.fullscreenElement) {
        if ('exitFullscreen' in document) {
          document?.exitFullscreen()
        } else if ('webkitExitFullscreen' in document) {
          ;(document as DocumentWithFullscreenAPI)?.webkitExitFullscreen?.()
        } else if ('mozCancelFullScreen' in document) {
          ;(document as DocumentWithFullscreenAPI)?.mozCancelFullScreen?.()
        } else if ('msExitFullscreen' in document) {
          ;(document as DocumentWithFullscreenAPI)?.msExitFullscreen?.()
        }
      }
      return EMPTY
    }),
  )
}

export const updatePageTitleByCurrentPriceEpic: RootEpic = (action$, state$) =>
  merge(
    combineLatest([
      action$.pipe(filter(isActionOf(getUserSuccess))),
      action$.pipe(filter(isActionOf(appChangeActiveAsset))),
      action$.pipe(filter(isActionOf(appChangeTradeMode))),
      action$.pipe(filter(isActionOf(pricesInitAction))),
    ]),
    action$.pipe(filter(isActionOf(pricesUpdatedAction))),
  ).pipe(
    switchMap(() => {
      const symbol = currentSymbolLabelSelector(state$.value)
      const price = currentPriceValueSelector(state$.value)
      if (symbol && price && process.env.NODE_ENV !== 'development') {
        sendPostMessage(PostMessageActionType.changePageTitle, `${symbol} ${price}`)
      }

      return EMPTY
    }),
  )

export default [
  loadConfigurationEpic,
  loadTranslationEpic,
  setActiveTabEpic,
  setActivePositionEpic,
  setOrderTypeEpic,
  setOverrideParamsEpic,
  changeAssetGroupEpic,
  changeLocationEpic,
  changeMobilePositionTypeEpic,
  tradingFormPanelEpic,
  defaultActiveAssetEpic,
  changeAssetGroupBySymbolEpic,
  accountLoginEpic,
  setActiveSignalEpic,
  changeActiveAssetEpic,
  toggleFullscreenEpic,
  updatePageTitleByCurrentPriceEpic,
]
