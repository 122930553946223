import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import isNil from 'lodash/isNil'

import { NumberInput } from '../../Controls/NumberInput'
import { formAmountSelector, formLotsSelector, currentSymbolPrecisionSelector } from '../../../../store/selectors'
import { numberFormat } from '../../../../utils'
import { NumberInputLabel } from '../../Controls/NumberInputLabel'

const AmountInput: FC<{ contractType: string }> = ({ contractType }) => {
  const { t } = useTranslation()
  const { value, fieldInformation } = useSelector(formAmountSelector)
  const precision = useSelector(currentSymbolPrecisionSelector)
  const lots = useSelector(formLotsSelector)

  if (isNil(precision)) {
    return null
  }

  return (
    <>
      <NumberInputLabel name="amount" unit={contractType} />
      <NumberInput
        name={'amount'}
        placeholder={t(`amount`, 'Amount')}
        value={numberFormat(value, {
          maximumFractionDigits: precision,
        })}
        fieldInformation={fieldInformation}
        lots={lots}
      />
    </>
  )
}

export default AmountInput
